import React, { useRef } from "react";
import { FaArrowRight } from "react-icons/fa";
import './button.css'


/**
 * Custom Primary Button with Hover Ripple Effect
 * @param {string} label - Text that needs to be displayed in the button.
 * @param {function|string} handleOnClick - The function or URL that runs when the button is clicked.
 *    - If a function is provided, it will be executed as a callback when the button is clicked.
 *    - If a string is provided, it will be used for navigation (either internal or external).
 * @returns {JSX.Element} The button component.
 *
 * Additional Notes:
 * - Includes a ripple effect on hover to enhance user interaction.
 * - Supports external and internal link navigation using `window.location`.
 * - The ripple effect is added dynamically based on mouse position.
 *
 * Example Usage:
 * ```jsx
 * <PrimaryButton
 *   label="Click Me"
 *   handleOnClick="https://example.com"
 * />
 * ```
 */

const PrimaryButton = ({ label, handleOnClick }) => {

  const buttonRef = useRef(null);


  //mouse hover effect
  const handleMouseOver = (e) => {
    const button = buttonRef.current;

    if (!button) return;


    const ripple = document.createElement("span");
    const rect = button.getBoundingClientRect();

    // Calculate the position of the ripple
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Style the ripple
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.className = "ripple";

    // Append ripple to button
    button.appendChild(ripple);


    // Remove ripple after animation
    setTimeout(() => {
      ripple.remove();
    }, 500);
  };




  // const navigate = useNavigate();
  const handleButtonClick = () => {
    if (typeof handleOnClick === "function") {
      // Call the function if handleOnClick is a function
      handleOnClick();
    } else if (typeof handleOnClick === "string") {
      // Handle link navigation if handleOnClick is a string
      if (handleOnClick.startsWith("http")) {
        window.location.href = handleOnClick; // External link
      } else {
        window.location.pathname = handleOnClick; // Internal link
      }
    }
  };
  
  return (
    <div>
      <button className="ContactBtn" onClick={() => { handleButtonClick(handleOnClick) }}
        ref={buttonRef}
        onMouseOver={handleMouseOver}
        style={{
          marginRight: '15px',
          backgroundColor: '#d19100',
          padding: '0.5em 1.5em',
          fontSize: '1em',
          marginTop: '10px',
          marginBottom: '10px',
          cursor: 'pointer',
          borderRadius: '52px',
          fontWeight: '500',
          color: '#1d1f48',
          borderStyle: 'none'
        }}>

        {/* button label */}
        {label}
        {/* arrow icon and it's styles */}
        <FaArrowRight style={{ marginLeft: '8px', verticalAlign: 'middle' }} />
      </button>
    </div>
  );

};

export default PrimaryButton; 