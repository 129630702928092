import React, { Suspense, useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';

import NavigationBar from "./components/util/nav/navBar";
import Footer from "./components/util/footer/footer";




import BlogPost from "./components/commonPageComponents/blogPost/blogPsot";

import CookiePolicyModal from "./components/util/cookies/cookiePolicyModal";
import ScrollToTop from "./components/util/scrolltoTop";

const Contact = React.lazy(() => import("./components/pages/contact/index"));
const AvailableSoon = React.lazy(() => import("./components/pages/error/avalableSoon"));
const PostPage = React.lazy(() => import("./components/pages/home/postPage/postPage"));

const SalesContactForm = React.lazy(() => import("./components/forms/sale-form"));
const Loadlock = React.lazy(() => import("./components/pages/products/platformSolutions/loadLock"));
const VacuumTransferModule = React.lazy(() => import("./components/pages/products/platformSolutions/vTM"));
const AtmosphericTransferModule = React.lazy(() => import("./components/pages/products/platformSolutions/aTM"));
const AutomationSolutions = React.lazy(() => import("./components/pages/products/automationSolutions"));
const EndEffector = React.lazy(() => import("./components/pages/products/platformSolutions/endEffector"));
const EquipmentSoftwareSolutions = React.lazy(() => import("./components/pages/services/equipmentSoftwareSolutions"));
const LoadPort = React.lazy(() => import('./components/pages/products/platformSolutions/loadPort'));
const ResourcesCenter = React.lazy(() => import("./components/pages/services/resourceCenter"));
const EFEMProductControlSys = React.lazy(() => import("./components/pages/services/efemProdContSys"));
const ManufactureingSubContract = React.lazy(() => import("./components/pages/services/manufacturingSubContract"));
const PresseReleases = React.lazy(() => import("./components/pages/resources/pressReleases"));
const ProductLaunches = React.lazy(() => import("./components/pages/resources/productLaunches"));
const KnowledgeHub = React.lazy(() => import("./components/pages/resources/knowledgeHub"));
const AboutUs = React.lazy(() => import("./components/pages/lifeAtMindOx/aboutUs"));
const Careers = React.lazy(() => import("./components/pages/lifeAtMindOx/careers"));
const JobDetails = React.lazy(() => import("./components/pages/lifeAtMindOx/careers/jobDetails/jobDetail"));

const CoatingCuringNCleaningTool = React.lazy(() => import('./components/pages/products/coatingCuringNCleaningTool'));
const CopperPassivationCoatingTool = React.lazy(() => import('./components/pages/products/copperPassivationCoatingTool'));
const Home = React.lazy(() => import('./components/pages/home'));
const PlatformSolutions = React.lazy(() => import('./components/pages/products/platformSolutions'));
const EFEM = React.lazy(() => import('./components/pages/products/efem'));
const Error404 = React.lazy(() => import('./components/pages/error/error404'))

function App() {
  useEffect(() => {
    // Clear all cookies
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });

    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Router>
        <ScrollToTop/>
        <NavigationBar />
        <Routes>
          {/* Default path has been modified for development pourpuses */}
          {/* <Route path="/" element={<Navigate to={"/platform-solutions"} />} /> */}
          <Route path="/" element={<Home />} />
          {/* <Route path="/platform-solutions" element={<PlatformSolutions />} > */}
          <Route path="/platform-solutions" element={<PlatformSolutions />} />
          <Route path="/platform-solutions/e-f-e-m" element={<EFEM />} />
          <Route path="/platform-solutions/end-effector" element={<EndEffector />} />
          <Route path="/platform-solutions/load-port" element={<LoadPort />} />
          <Route path="/platform-solutions/loadlock" element={<Loadlock/>} />
          <Route path="/platform-solutions/vacuum-transfer-module" element={<VacuumTransferModule/>} />
          <Route path="/platform-solutions/atmospheric-transfer-module" element={<AtmosphericTransferModule />} />

          <Route path="/coating,-curing-&-cleaing-tool" element={<CoatingCuringNCleaningTool />} />
          <Route path="/copper-passivation-coating-tool" element={<CopperPassivationCoatingTool />} />

          <Route path="/automation-solutions" element={<AutomationSolutions />} />
          <Route path="/semiconductor-equipment-software-solution" element={<EquipmentSoftwareSolutions />} />
          <Route path="/manufacutring-sub-contract" element={<ManufactureingSubContract />} />
          <Route path="/e-f-e-m-product-control-system" element={<EFEMProductControlSys />} />
          <Route path="/engineering-resources" element={<ResourcesCenter />} />

          <Route path="/press-releases" element={<PresseReleases />} />
          <Route path="/product-launches" element={<ProductLaunches />} />
          <Route path="/knowledge-hub" element={<KnowledgeHub />} />

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/life-at-mindox/careers" element={<Careers />} />

          {/* Redirects within the website */}
          <Route path="/company/careers" element={<Navigate to={"/life-at-mindox/careers"} />} />
          <Route path="/life-at-mindox" element={<Navigate to={"/about-us"} />} />
          <Route path="/life-at-mindox/careers/vacancy" element={<Navigate to={"/life-at-mindox/careers"} />} />

          <Route path="/life-at-mindox/careers/vacancy/:id" element={<JobDetails />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/salse-contact" element={<SalesContactForm />} />
          <Route path="/available-soon" element={<AvailableSoon/>} />

          <Route path="/press-releases/sample-post" element={<PostPage/>} />
          <Route path="/*" element={<Error404 />} />
        </Routes>
        <a aria-label="WhatsApp" className="whatsapp" href="https://web.whatsapp.com/send?phone=+6581128732&text&app_absent=0" target="blank"  >
          <i className="bi bi-whatsapp my-float"></i>
        </a>
        <CookiePolicyModal/>
        <Footer />
      </Router>
    </>
  );
}

export default App;
