import React from "react";
import './footer.css'
const Footer = () => {
    return (
        <>
            <footer className="pt-4 mt-4 footer-container" >
                <div className="container ">
                    <div className="grid row d-flex flex-wrap justify-content-between">
                        <div className="col">
                            <p className="fw-bold">Products</p>
                            <ul className="fw-light footer-list">
                                <li><a href="/platform-solutions">Platform Solutions</a></li>
                                <li><a href="/coating,-curing-&-cleaing-tool">Coating, Curing & Cleaing Tool</a></li>
                                <li><a href="/copper-passivation-coating-tool">Copper Passivation Coating Tool</a></li>
                                <li><a href="/automation-solutions">Automation Solutions</a></li>
                            </ul>
                        </div>
                        <div className="col">
                            <p className="fw-bold">Services</p>
                            <ul className="fw-light footer-list">
                                <li><a href="/semiconductor-equipment-software-solution">Equipment Software Solutions</a></li>
                                <li><a href="/engineering-resources">MindOx Resource Center</a></li>
                                <li><a href="/manufacutring-sub-contract">Manufacutring Sub-Contract</a></li>
                                <li><a href="/available-soon">Legacy Tool Convertion</a></li>
                                <li><a href="/available-soon">Post Warranty Service Packages</a></li>
                            </ul>
                        </div>
                        <div className="col">
                            <p className="fw-bold">Resources</p>
                            <ul className="fw-light footer-list">
                                <li><a href="/press-releases">Press Releases</a></li>
                                <li><a href="/product-launches">Product Launches</a></li>
                                <li><a href="/knowledge-hub">Knowledge Hub</a></li>
                                <li><a href="/available-soon">Training Center</a></li>
                            </ul>

                        </div>
                        <div className="col">
                            <p className="fw-bold">Life at MindOx</p>
                            <ul className="fw-light footer-list">
                                <li><a href="/about-us">About Us</a></li>
                                <li><a href="/life-at-mindox/careers">Careers</a></li>
                            </ul>
                        </div>
                        <div className="col">
                            <p className="fw-bold">Want to know more
                                or do you have a
                                question?
                            </p>
                            <p className="fw-light footer-list">
                                <span className="fw-medium"><a href="/available-soon" style={{ color: '#1d1f48' }}>Book a demo</a></span>
                                <br />
                                Or you can reach out to
                                us on workdays from
                                9am till 5pm.

                                <br />
                                <br />
                                Singapore
                                +65 6983 8172
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row ">
                        <div className="text-center ">
                            <p>
                                <a href="https://www.linkedin.com" className="text-black ms-2" target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a> |
                                <a href="https://www.x.com/" className="text-black ms-2" target="_blank" rel="noopener noreferrer"><i className="bi bi-twitter-x"></i></a> |
                                <a href="https://www.instagram.com" className="text-black ms-2" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a> |
                                <a href="https://www.facebook.com" className="text-black ms-2" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a>
                            </p>
                            <p>&copy; 2025 MindOx Techno. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;