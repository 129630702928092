import React, { useState, useEffect } from 'react';
import { Button, Toast } from 'react-bootstrap';

const CookieBanner = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Check if the cookie policy has been accepted
    if (!localStorage.getItem('cookiePolicyAccepted')) {
      setShow(true);
    }
  }, []);

  const handleAccept = () => {
    // Save user acceptance to localStorage
    localStorage.setItem('cookiePolicyAccepted', 'true');
    setShow(false);
  };

  if (!show) {
    return null; // Don't show the banner if already accepted
  }

  return (
    <div className="position-fixed bottom-0 start-0 w-60 bg-dark text-light p-3" style={{ zIndex: '6' }}>
      <div className="d-flex flex-column justify-content-between align-items-start">
        <div>
          <span>
            We use cookies to improve your experience. By using our site, you agree to our <span className='fw-bold text-decoration-underline text-reset'><a className='text-light' href='#'>cookie policy</a></span>.
          </span>
        </div>
        <div className='pt-2'>
          <Button variant="light" size="sm" onClick={handleAccept}>
            Accept
          </Button>
        </div>

      </div>
    </div>
  );
};

export default CookieBanner;
