import React from "react";


/**
 * BlogPost Component
 * @param {string} title - Title of the blog post.
 * @param {string} imageSrc - Source of the blog image.
 * @param {string} imageAlt - Alt text for the blog image.
 * @param {string} author - Name of the author.
 * @param {string} date - Date of the blog post.
 * @param {string} content - Content of the blog post.
 * @returns {JSX.Element} - The blog post component.
 */
const BlogPost = ({ title, imageSrc, imageAlt, author, date, content }) => {
  return (
    <div className="container mt-4" >
      <div className="card shadow border-0" style={{color:'#1d1f48'}}>
        {/* Blog Title */}
        <div className="card-header text-center">
          <h2  >{title}</h2>
        </div>

        {/* Blog Image */}
        <div className="card-body text-center">
          <img
            src={imageSrc}
            alt={imageAlt}
            className="img-fluid rounded"
            style={{ maxHeight: "400px", maxWidth: "100%" }}
          />
        </div>

        {/* Blog Author and Date */}
        <div className="card-body d-flex flex-column justify-content-between">
          <p className="m-0 px-5">By: {author}</p>
          <p className="px-5">{date}</p>
        </div>

        {/* Blog Content */}
        <div className="card-body">
          <p className="card-text text-justify px-5 pb-3">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
